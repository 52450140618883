import {Component, EventEmitter, Input, OnDestroy, Output, ViewChild} from '@angular/core';
import {ModalComponent} from '@nit-components';
import {FeathersHubService} from '@nit-core/global/domain/enums';
import {Room} from '@nit-core/models/room';
import {FeathersService} from '@nit-services';
import {Subject, takeUntil} from 'rxjs';

@Component({
  selector: 'nit-delete-room-modal',
  templateUrl: './delete-room-modal.component.html',
  styleUrl: './delete-room-modal.component.scss',
  standalone: true,
  imports: [ModalComponent]
})
export class DeleteRoomModalComponent implements OnDestroy {
  @ViewChild('modal') confirm: ModalComponent;
  @Output() submitted: EventEmitter<void> = new EventEmitter<void>();
  @Output() closed: EventEmitter<void> = new EventEmitter<void>();
  @Input() room: Room;

  private readonly _unsubscribe$ = new Subject<void>();

  constructor(private readonly _feathersService: FeathersService) { }

  open(): void {
    this.confirm.open();
  }

  onDelete(): void {
    this._feathersService.remove(FeathersHubService.Rooms, this.room._id)
      .pipe(takeUntil(this._unsubscribe$))
      .subscribe(() => {
        this.confirm.close();
        this.submitted.emit();
      });
  }

  ngOnDestroy(): void {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }
}
