<nit-modal #modal id="delete-room-modal" titleSize="small"
  [width]="422" [title]="room?.type === 'private'
  ? 'Ви дійсно бажаєте видалити цей чат?'
  : 'Ви впевненні, що хочете видалити цей груповий чат?'"
  [subTitle]="room?.type === 'direct' ? 'Він стане недоступним для всіх користувачів' : ''"
  [isPopup]="true" (closed)="closed.emit()">
  <div class="room-info">
    <div class="button-action">
      <button class="cancel" (click)="confirm.close()">Скасувати</button>
      <button class="delete" (click)="onDelete()">Видалити</button>
    </div>
  </div>
</nit-modal>
